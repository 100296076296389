import { Box, IconButton, InputAdornment, SxProps } from '@mui/material'
import { Button, TextField } from 'components/Forms'
import { Card, DataGrid, GridColDef, PageTitle } from 'components/UI'
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Visibility as ViewIcon,
  CloudDownload as CloudDownloadIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { usersService } from 'services'
import { useDebounce } from 'use-debounce'
import { useUserNavigate, CsvDownloader } from 'helpers/Hooks'
import { User } from 'services/users.service'
import { PaginationParams } from 'helpers/Types'
import { readableDate } from 'helpers/Methods'
import { VIEW_SUBPATH } from 'helpers/Constants'

const exportUsers = async () => {
  const { response } = await usersService.exportUsers()
  CsvDownloader(response.data.columns, response.data.users, 'Users.csv')
}

export default function UserList() {
  const [users, setUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)

  const navigate = useUserNavigate()

  const fetchUsers = useCallback(
    async (params: PaginationParams) => {
      setIsLoading(true)
      try {
        const { response } = await usersService.users({
          data: {
            ...params,
            search: debouncedQuery,
          },
        })
        setUsers(response.data.users)
        setCount(response.data.count)
      } finally {
        setIsLoading(false)
      }
    },
    [debouncedQuery],
  )

  useEffect(() => {
    fetchUsers({ currentPage })
  }, [fetchUsers, debouncedQuery, currentPage])

  const columns: GridColDef<User>[] = [
    {
      headerName: 'Name',
      field: 'FirstName',
      valueGetter: (params, row) =>
        `${row.TitleName ?? ''} ${row.FirstName ?? ''} ${row.MiddleName ?? ''} ${row.LastName ?? ''}`,
    },
    { headerName: 'Phone', field: 'primaryPhone' },
    { headerName: 'Email', field: 'Email' },
    { headerName: 'DOB', field: 'DOB' },
    { headerName: 'Enroll Date', field: 'CreatedDate', valueGetter: (value) => readableDate(value) },
    {
      headerName: 'Actions',
      field: 'ID',
      width: 75,
      renderCell: ({ value }) => (
        <IconButton
          onClick={() => {
            navigate(`${VIEW_SUBPATH}?id=${value}`)
          }}
        >
          <ViewIcon />
        </IconButton>
      ),
    },
  ]

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={usersStyles.titleWrapper}>
          <Box sx={usersStyles.searchWrapper}>
            <TextField
              placeholder="Search Users"
              value={searchKeyword}
              onChange={(event) => {
                setSearchKeyword(event.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => setSearchKeyword('')} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={usersStyles.toolsWrapper}>
            <Button sx={usersStyles.button} startIcon={<CloudDownloadIcon />} onClick={exportUsers}>
              {CONTENT_LABELS.downloadCSV}
            </Button>
          </Box>
        </Box>

        <Box sx={usersStyles.tableWrapper}>
          <DataGrid
            getRowId={(row) => row.ID}
            rows={users}
            columns={columns}
            rowCount={count}
            onPaginationModelChange={(data) => {
              fetchUsers({ currentPage: data.page + 1 })
              setCurrentPage(data.page + 1)
            }}
            loading={isLoading}
          />
        </Box>
      </Card>
    </Box>
  )
}

interface UsersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

export const usersStyles: UsersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { md: 'row', xs: 'column' },
    gap: { md: 0, xs: '10px' },
  },
  searchWrapper: { width: { md: 300, xs: '100%' } },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'Users Listing',
  filterButton: 'Filters',
  addButton: 'Add Users',
  filterModalHeader: 'Filter Data',
  downloadCSV: 'CSV',
}
