import { RightSidebarProps } from 'components/Navigation/Sidebar'
import { LABELS, validationSchema } from './Components'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { promoCodeService } from 'services'
import useToast from 'components/UI/Toast'
import AddPromoCodeDrawer from './AddPromoCodeDrawer'
import dayjs from 'dayjs'

interface AddPromoCodeProps extends Omit<RightSidebarProps, 'headerText'> {
  onAddSuccess: () => void
}

export default function AddPromoCode({ onAddSuccess, ...props }: AddPromoCodeProps) {
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      promo_code: '',
      percent_amount: null,
      flat_amount: null,
      min_amount: null,
      max_amount: null,
      multi_use: 0,
      single_use: 0,
      code_start_date: dayjs(),
      code_end_date: dayjs(),
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {},
    validateOnMount: true,
  })
  const onSave = useCallback(async () => {

    if (!formik.isValid) {
      formik.submitForm()
      return
    }

    setLoading(true)
    const { values } = formik
    try {
      await promoCodeService.addPromoCode({
        ...values,
        single_use: values.multi_use === 1 ? 0 : 1
      })
      onAddSuccess()
      props.onClose()
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [formik, onAddSuccess, props, toast])

  return (
    <AddPromoCodeDrawer headerText={LABELS.addPromoCode} loading={loading} onSave={onSave} formik={formik} {...props} />
  )
}
