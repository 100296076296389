/* eslint-disable sonarjs/deprecation */
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Grid,
  SxProps,
  FormControl, SelectChangeEvent
} from '@mui/material'
import { PageTitle } from 'components/UI'
import { CHART_THEME } from 'helpers/Constants'
import Highcharts from 'highcharts'
import { dashboardService } from 'services'
import { fontFamily } from 'styles/theme'
import { CalendarDateRangeType, DateRangePicker } from 'components/Forms'
import { formatDateForApi } from 'helpers/Methods'
import { COLORS } from 'styles'
import { BarChart, Bookings, SimpleState, TopPie } from './Widgets'
import useToast from 'components/UI/Toast'
import { useAppSelector } from 'redux/hooks'
import { selectUser } from 'redux/Auth/auth.selectors'
import MultiSelect from 'components/Forms/MultiSelect'
import { MultiSelectItem } from 'components/Forms/MultiSelect/MultiSelect'

export type TodaysBookings = {
  id: number | string
  bookingNum: string
  created: string
  name: string
  price: number
  status: string
}

interface DashboardDataObject {
  newUsers?: number
  newBookings?: number
  profit?: number
  profitPercent?: number
  revenueDataPerMonth?: {
    x_axis: string
    // total_orders: number
    y_axis: number
  }[]
  usersDataPerMonth?: {
    x_axis: string
    y_axis: number
  }[]
  revenueByDomain?: {
    total_revenue: number
    domain: number
  }[]
  eventsPerMonth?: {
    x_axis: string
    y_axis: number
  }[]
  todaysBookings?: TodaysBookings[]
  yesterdaysBookings?: TodaysBookings[]
  dayBeforeYesterdaysBookings?: TodaysBookings[]
}

function Dashboard() {
  const [dashboardData, setDashboardData] = useState<DashboardDataObject>({
    todaysBookings: [],
    yesterdaysBookings: [],
    dayBeforeYesterdaysBookings: [],
    eventsPerMonth: [],
  })

  const [dashboardAnalyticsData, setDashboardAnalyticsData] = useState<DashboardDataObject>({
    revenueByDomain: [],
    newUsers: 0,
    newBookings: 0,
    profit: 0,
    profitPercent: 0,
  })
  const toast = useToast()
  const userDomains = useAppSelector(selectUser)?.userDomains
  const [analyticsDateRange, setAnalyticsDateRange] = useState<CalendarDateRangeType>([new Date(), new Date()])
  const initDomainValues = useMemo(() => userDomains?.map((domain) => domain.domain.id) , [userDomains])
  const [domains, setDomains] = useState<number[]>(initDomainValues)

  const handleDomainChange = (event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event
    if (value.at(-1) === "all") {
      setDomains(domains.length === userDomains.length ? [] : initDomainValues)
      return
    }

    setDomains(value as number[])
  }

  const getDashboardAnalyticsData = useCallback(async () => {
    try {
      const { response } = await dashboardService.dashboardAnalytics({
        startDate: formatDateForApi(analyticsDateRange[0]),
        endDate: formatDateForApi(analyticsDateRange[1]),
        domain: domains.join(',') || initDomainValues.join(','),
      })

      if (response) {
        setDashboardAnalyticsData({
          newUsers: response.data.newUsers,
          newBookings: response.data.newBookings,
          profit: response.data.profit,
          profitPercent: response.data.profitPercent,
          revenueByDomain: response.data.revenueByDomain,
        })
      }
    } catch {
      toast.error('Error Processing your request!')
    }
  }, [analyticsDateRange, domains, initDomainValues, toast])

  useEffect(() => {
    getDashboardAnalyticsData()
  }, [analyticsDateRange, domains, getDashboardAnalyticsData])

  const getDashboardData = useCallback(async () => {
    try {
      const { response } = await dashboardService.dashboard({
        domain: domains.join(',') || initDomainValues.join(','),
      })
      if (response.data) {
        setDashboardData(response.data)
      }
    } catch {
      toast.error('Error Processing your request!')
    }
  }, [domains, initDomainValues, toast])

  useEffect(() => {
    getDashboardData()
  }, [domains, getDashboardData])

  return (
    <Box>
      <Box sx={dashboardStyles.pageHeaderWrapper}>
        <PageTitle>Dashboard</PageTitle>

        {/* Toolbar */}
        <Box sx={dashboardStyles.filterWrapper}>
          <Box sx={{ ...dashboardStyles.toolsWrapper, mr: '5px' }}>
            <FormControl sx={{ width: { xs:"100%", sm: 250 } }} size="small">
              <MultiSelect
                value={domains}
                onChange={handleDomainChange}
                renderValue={(selected: number[]) =>
                  selected.map((id) => userDomains.find((domain) => domain?.domain.id === id)?.domain.name).join(', ')
                }
                total={userDomains?.length}
                title='Domains'
              >
                {userDomains?.map((domain) => (
                  <MultiSelectItem key={domain.domain.id} label={domain.domain.name} value={domain.domain.id} checked={domains.includes(domain.domain.id)} />
                ))}
              </MultiSelect>
            </FormControl>
          </Box>
          <Box  sx={dashboardStyles.toolsWrapper}>
            <DateRangePicker
              value={analyticsDateRange}
              onChange={(range) => {
                setAnalyticsDateRange(range)
              }}
              disableFutureDates
              // displayValue
              customDisplayCalendarIcon
              fullWidth
            />
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {/* <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <SimpleState
                // Icon={GroupAddIcon}
                dateRangeProps={{
                  value: usersDateRange,
                  onChange: (range) => {
                    setUsersDateRange(range)
                  },
                  disableFutureDates: true,
                }}
                enableDateRange
                state={userCounts}
                label="New Subscribers"
              />
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <SimpleState
                // Icon={LocalActivityIcon}
                state={bookingCounts}
                label="Total Bookings"
                theme={CHART_THEME.SECONDARY}
                dateRangeProps={{
                  value: analyticsDateRange,
                  onChange: (range) => {
                    setAnalyticsDateRange(range)
                  },
                  disableFutureDates: true,
                }}
                enableDateRange
              />
            </Grid>

            <Grid item sm={12}>
              <BarChart
                titleText="Users This Year"
                chartHeight="240px"
                data={dashboardData.usersDataPerMonth}
              />
            </Grid>
          </Grid>
        </Grid> */}

        {/* New Subscribers */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState state={dashboardAnalyticsData.newUsers} label="New Subscribers" />
        </Grid>

        {/* Total Bookings  */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={dashboardAnalyticsData.newBookings}
            label="Total Bookings"
            theme={CHART_THEME.SECONDARY}
          />
        </Grid>

        {/* Total Profit  */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={`$${dashboardAnalyticsData.profit}`}
            label="Total Profit"
            theme={CHART_THEME.TERTIARY}
          />
        </Grid>

        {/* Total Profit Percentage */}
        <Grid item md={3} sm={6} xs={12}>
          <SimpleState
            // Icon={LocalActivityIcon}
            state={`${dashboardAnalyticsData.profitPercent}%`}
            label="Profit Percentage"
            theme={CHART_THEME.DEEP_SAFFRON}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart titleText="Monthly Subscribers" chartHeight="360px" data={dashboardData.usersDataPerMonth} />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart titleText="Monthly Revenue" chartHeight="360px" data={dashboardData.revenueDataPerMonth} currency />
          {/* <AreaChart
            titleText="Sales This year"
            data={dashboardData.revenueDataPerMonth}
          /> */}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <BarChart titleText="Event Dates By Month" chartHeight="350px" data={dashboardData.eventsPerMonth} currency />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TopPie titleText="Sales" data={dashboardAnalyticsData.revenueByDomain} chartHeight="305px" />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Bookings
            todayBookings={dashboardData.todaysBookings}
            yesterdayBookings={dashboardData.yesterdaysBookings}
            dayBeforeYesterdayBookings={dashboardData.dayBeforeYesterdaysBookings}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

interface DashboardStyles {
  pageHeaderWrapper: SxProps
  filterWrapper: SxProps
  toolsWrapper: SxProps
  toolIconButton: SxProps
  toolIcon: SxProps
}

const dashboardStyles: DashboardStyles = {
  pageHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { md: 'row', xs: 'column' },
  },
  filterWrapper: {
    display: 'inline-flex',
    flexDirection: { md: 'row', xs: 'column' },
    gap: { md: 0, xs: '10px' },
  },
  toolsWrapper: {
    display: 'flex',
    padding: '0 10px',
    border: `1px solid ${COLORS.CADET_BLUE}`,
    borderRadius: '50px',
    minHeight: '42px',
  },
  toolIconButton: {
    p: 0.5,
  },
  toolIcon: {
    color: COLORS.BLACK,
    '&:hover': { color: COLORS.PRIMARY_DARK },
  },
}

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily,
    },
  },
  accessibility: {
    enabled: false,
  },
})

export default Dashboard
