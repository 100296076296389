import { RightSideBar, RightSidebarProps } from 'components/Navigation/Sidebar'
import { componentsStyles as styles } from '../../CRMUsers/__components__/Components.styles'
import { Box, FormControlLabel, FormControl, FormLabel, Typography, RadioGroup, Radio } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Card } from 'components/UI'
import { formFieldsConfig, LABELS } from './Components'
import { TextFieldOutlined } from 'components/Forms/TextField'
import { useFormik } from 'formik'
import dayjs from 'dayjs'

interface PromoCodeInfo {
  promo_code: string
  percent_amount: number
  flat_amount: number
  min_amount: number
  max_amount: number
  multi_use: number
  single_use: number
  code_start_date: any
  code_end_date: any
}

interface AddPromoCodeDrawerProps extends RightSidebarProps {
  loading: boolean
  onSave: () => void
  formik: ReturnType<typeof useFormik<PromoCodeInfo>>
}

export default function AddPromoCodeDrawer({ onSave, loading, formik, ...props }: AddPromoCodeDrawerProps) {
  const getFormikFields = (field: string) => {
    const isDateField = ['code_start_date', 'code_end_date'].includes(field)
    let fieldValue
    if (isDateField) {
      fieldValue = formik.values[field] ? dayjs(formik.values[field]) : null // Ensure Moment.js compatibility
    } else {
      fieldValue = formik.values[field]
    }
    return {
      value: fieldValue,
      onBlur: formik.handleBlur,
      onChange: isDateField ? (value: any) => formik.setFieldValue(field, value) : formik.handleChange,
      error: formik.touched[field] && Boolean(formik.errors[field]),
      helperText: formik.touched[field] && formik.errors[field],
    }
  }
  return (
    <RightSideBar
      bodyStyles={styles.wrapper}
      footerActions={[
        {
          label: 'Save',
          type: 'submit',
          onClick: onSave,
          loading,
        },
      ]}
      {...props}
    >
      <Typography sx={styles.sectionHeading}>{LABELS.promoCodeInfo}</Typography>
      <Card sx={styles.sectionCard}>
        <Box sx={styles.userInfoContainer}>
          <TextFieldOutlined
            {...formFieldsConfig.promo_code}
            {...getFormikFields('promo_code')}
            size="small"
            fullWidth
          />
          <TextFieldOutlined
            {...formFieldsConfig.percent_amount}
            {...getFormikFields('percent_amount')}
            size="small"
            fullWidth
          />
          <TextFieldOutlined
            {...formFieldsConfig.flat_amount}
            {...getFormikFields('flat_amount')}
            size="small"
            fullWidth
          />
          <TextFieldOutlined
            {...formFieldsConfig.min_amount}
            {...getFormikFields('min_amount')}
            size="small"
            fullWidth
          />
          <TextFieldOutlined
            {...formFieldsConfig.max_amount}
            {...getFormikFields('max_amount')}
            size="small"
            fullWidth
          />
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">Multi Use</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="multi_use"
              {...formFieldsConfig.multi_use}
              {...getFormikFields('multi_use')}
            >
              <FormControlLabel value={1} control={<Radio />} label="Yes" />
              <FormControlLabel value={0} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...formFieldsConfig.code_start_date}
              {...getFormikFields('code_start_date')}
              slots={{ textField: TextFieldOutlined }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...formFieldsConfig.code_end_date}
              {...getFormikFields('code_end_date')}
              slots={{ textField: TextFieldOutlined }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Card>
    </RightSideBar>
  )
}
