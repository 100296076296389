import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  SxProps,
  Typography,
} from '@mui/material'
import { Button, CalendarDateRangeType, DateRangePicker, TextField } from 'components/Forms'
import { Card, Chip, DataGrid, GridColDef, PageTitle } from 'components/UI'
import {
  Search as SearchIcon,
  Tune as FilterIcon,
  Clear as ClearIcon,
  Visibility as ViewIcon,
  CloudDownload as CloudDownloadIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  AirplaneTicket as AirplaneTicketIcon,
  Apartment as ApartmentIcon,
  Shield as ShieldIcon,
  LocalActivity as LocalActivityIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BOOKING_STATUS_COLOR, MODULES } from 'helpers/Constants'
import { bookingsService } from 'services'
import { useDebounce } from 'use-debounce'
import RightSidebar from 'components/Navigation/Sidebar/RightSidebar'
import { VIEW_SUBPATH } from 'helpers/Constants/path'
import { CsvDownloader, useOrderNavigate } from 'helpers/Hooks'
import { currencyFormatter } from 'helpers/Methods/utils'
import { useAppSelector } from 'redux/hooks'
import { selectUser } from 'redux/Auth/auth.selectors'
import { DomainItem } from 'pages/CRMUsers/__components__/Components'
import { fontWeight } from 'styles/theme'
import useToast from 'components/UI/Toast'
import { useGridApiRef } from '@mui/x-data-grid'
import { usePermission } from 'components/Core/Permissions'
import { Bookings } from 'services/bookings.types'
import { selectLookupData } from 'redux/App/app.selectors'
import { formatDateForApi, readableDate } from 'helpers/Methods/date'
import { COLORS } from 'styles'
import { subMonths } from 'date-fns'

const exportOrders = async () => {
  const { response } = await bookingsService.exportBookings()
  CsvDownloader(response.data.columns, response.data.bookings, 'orders.csv')
}

export default function OrderList() {
  const navigate = useOrderNavigate()
  const lookupData = useAppSelector(selectLookupData)
  const [bookings, setBookings] = useState([])
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const gridApiRef = useGridApiRef()
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)
  const userDomains = useAppSelector(selectUser)?.userDomains
  const initDomainValues = useMemo(() => userDomains?.map((domain) => domain.domain.id), [userDomains])
  const [domains, setDomains] = useState<number[]>(initDomainValues)
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const { getPermission } = usePermission()
  const permission = useMemo(() => getPermission(MODULES.ORDERS), [getPermission])
  const currentDate = new Date()
  const [bookingDateRange, setBookingDateRange] = useState<CalendarDateRangeType>([
    subMonths(currentDate, 6),
    currentDate,
  ])

  const columns: GridColDef<Bookings>[] = useMemo(
    () => [
      { headerName: 'Order', field: 'bookingNum' },
      { headerName: 'Customer', field: 'name' },
      {
        headerName: 'Products',
        field: 'products',
        renderCell: ({ value }) => (
          <>
            {value?.ticket && <ConfirmationNumberIcon />}
            {value?.flight && <AirplaneTicketIcon />}
            {value?.hotel && <ApartmentIcon />}
            {value?.insurance && <ShieldIcon />}
            {value?.addOns && <LocalActivityIcon />}
          </>
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        renderCell: ({ value }) => {
          const status = lookupData.bookingStatus.find((item) => item.id === value)?.status
          return <Chip size="small" label={status} color={BOOKING_STATUS_COLOR[status]} borderLess />
        },
      },
      {
        headerName: 'Total',
        field: 'price',
        renderCell: ({ value }) => <>{permission.canViewPaymentData ? currencyFormatter(value) : value}</>,
      },
      { headerName: 'Currency', field: 'currency' },
      { headerName: 'Date Added', field: 'created', renderCell: ({ value }) => <>{readableDate(value)}</> },
      {
        headerName: 'Domain',
        field: 'domain',
        renderCell: ({ value }) => {
          const domain = lookupData.domains.find((item) => item.id === value)
          return <DomainItem domain={domain} />
        },
      },
      {
        headerName: 'Actions',
        field: 'id',
        renderCell: ({ value }) => (
          <>
            {permission.read && (
              <IconButton
                onClick={() => {
                  navigate(`${VIEW_SUBPATH}?id=${value}`)
                }}
              >
                <ViewIcon />
              </IconButton>
            )}
          </>
        ),
      },
    ],
    [lookupData, permission, navigate],
  )

  const fetchOrders = useCallback(
    async ({ currentPage = 1 }) => {
      setIsLoading(true)
      try {
        const { response } = await bookingsService.bookings({
          currentPage,
          search: debouncedQuery,
          domain: domains.join(',') || initDomainValues.join(','),
          startDate: formatDateForApi(bookingDateRange[0]),
          endDate: formatDateForApi(bookingDateRange[1]),
        })
        setBookings(response.data.bookings)
        setCount(response.data.count)
      } catch (error) {
        toast.error(error?.data?.message)
      } finally {
        setIsLoading(false)
      }
    },
    [debouncedQuery, domains, initDomainValues, bookingDateRange, toast],
  )

  const toggleFilters = useCallback(() => {
    setFiltersOpen(!isFiltersOpen)
  }, [isFiltersOpen])

  useEffect(() => {
    fetchOrders({ currentPage })
  }, [fetchOrders, debouncedQuery, domains, bookingDateRange, currentPage])

  const onDomainChange = (id: number, checked: boolean) => {
    let domain = [...domains]
    if (checked) {
      domain.push(id)
    } else {
      domain = domain.filter((item) => item !== id)
    }
    setDomains(domain)
  }

  const totalDomainsCount = userDomains.length
  const selectedDomainsCount = domains.length
  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={ordersStyles.titleWrapper}>
          <Box sx={ordersStyles.searchWrapper}>
            <TextField
              placeholder="Search Orders"
              value={searchKeyword}
              onChange={(event) => {
                setSearchKeyword(event.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => setSearchKeyword('')} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={ordersStyles.toolsWrapper}>
            <Button variant="outlined" sx={ordersStyles.button} startIcon={<FilterIcon />} onClick={toggleFilters}>
              {CONTENT_LABELS.filterButton}
            </Button>

            {/* <Button sx={ordersStyles.button}>{CONTENT_LABELS.addButton}</Button> */}
            <Button sx={ordersStyles.button} startIcon={<CloudDownloadIcon />} onClick={exportOrders}>
              {CONTENT_LABELS.downloadCSV}
            </Button>
          </Box>
        </Box>

        <Box sx={ordersStyles.tableWrapper}>
          <DataGrid
            apiRef={gridApiRef}
            rows={bookings}
            columns={columns}
            rowCount={count}
            onPaginationModelChange={(data) => {
              console.log('TCL ~ OrderList ~ data:', data)
              setCurrentPage(data.page + 1)
              fetchOrders({ currentPage: data.page + 1 })
            }}
            loading={isLoading}
          />
        </Box>
      </Card>

      <RightSidebar
        open={isFiltersOpen}
        onClose={toggleFilters}
        headerText={CONTENT_LABELS.filterModalHeader}
        // footerActions={[
        //   {
        //     label: 'Save',
        //     onClick: toggleFilters,
        //   },
        // ]}
      >
        <Typography sx={ordersStyles.sectionHeading}>{CONTENT_LABELS.filterDateHeader}</Typography>
        <Card sx={ordersStyles.sectionCard}>
          <Box sx={ordersStyles.calenderWrapper}>
            <DateRangePicker
              value={bookingDateRange}
              onChange={(range) => {
                setBookingDateRange(range)
              }}
              disableFutureDates
              // displayValue
              customDisplayCalendarIcon
              fullWidth
            />
          </Box>
        </Card>
        <Typography sx={ordersStyles.sectionHeading}>{CONTENT_LABELS.filterDomainHeader}</Typography>
        <Card sx={ordersStyles.sectionCard}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox indeterminate={selectedDomainsCount > 0 && selectedDomainsCount < totalDomainsCount} />
              }
              label={'Select All'}
              checked={totalDomainsCount === selectedDomainsCount}
              onChange={() => {
                setDomains(selectedDomainsCount === totalDomainsCount ? [] : initDomainValues)
              }}
            />
            {userDomains.map((domain) => (
              <FormControlLabel
                control={<Checkbox />}
                label={<DomainItem domain={domain.domain} />}
                checked={domains.includes(domain.domain.id)}
                onChange={(_event, checked) => {
                  onDomainChange(domain.domain.id, checked)
                }}
                key={domain.domain.id}
              />
            ))}
          </FormGroup>
        </Card>
      </RightSidebar>
    </Box>
  )
}

interface OrdersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
  sectionHeading: SxProps
  sectionCard: SxProps
  calenderWrapper: SxProps
}

const ordersStyles: OrdersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { md: 'row', xs: 'column' },
    gap: { md: 0, xs: '10px' },
  },
  searchWrapper: { width: { md: 300, xs: '100%' } },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
  sectionHeading: {
    width: { sm: 400, xs: 300 },
    fontSize: '16px',
    fontWeight: fontWeight.medium,
    mt: 2,
    mb: 1,
  },
  sectionCard: {
    borderRadius: '5px',
    mb: 1,
    p: 2,
    fontSize: '12px',
  },
  calenderWrapper: {
    display: 'flex',
    padding: '5px 10px',
    border: `1px solid ${COLORS.CADET_BLUE}`,
    borderRadius: '5px',
    width: '100%',
  },
}

const CONTENT_LABELS = {
  pageTitle: 'Orders Listing',
  filterButton: 'Filters',
  addButton: 'Add Order',
  downloadCSV: 'CSV',
  filterModalHeader: 'Filter Data',
  filterDomainHeader: 'Domain',
  filterDateHeader: 'Date',
}
