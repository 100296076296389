import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { customerAnalyticsResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { ObjectOf } from 'helpers/Types'
import { GraphData } from 'pages/CustomerAnalytics/CustomerAnalytics'

interface CustomerAnalyticsResponse {
  stats: {
    avgHotelStars: number
    avgReviewRating: number
    avgNights: number
    avgRooms: number
    orderEventGap: number
    quantityPerOrder: number
    averagePrice: number
    avgAge: number
  }
  chart: ObjectOf<GraphData>
}

const getAnalyticsData = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<CustomerAnalyticsResponse>(customerAnalyticsResponse)
    : apiHandler.getRequest<CustomerAnalyticsResponse>('customer-analytics', data)

const customerAnalytics = {
  getAnalyticsData,
}

export default customerAnalytics
