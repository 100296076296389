import { Avatar, Box, Grid2 as Grid } from '@mui/material'
import { componentsStyles as styles } from './Components.styles'
import { Domain, Permission } from 'redux/Auth/auth.state'
import { usersListStyles } from '../CrmUsers.styles'
import { permissionLabels } from 'helpers/Strings/labels'
import { object, string } from 'yup'

export const LABELS = {
  userInfo: 'User Details',
  domains: 'Domains',
  permissions: 'Permissions',
  addUser: 'Add User',
}

export function DomainItem({ domain }: { domain: Domain }) {
  if(!domain) return null
  return (
    <Box sx={styles.domainItem}>
      <Avatar sx={{ backgroundColor: domain.color, ...usersListStyles.domainAvatar }} title={domain.name}>
        {domain.name.slice(0, 1)}
      </Avatar>
      {domain.name}
    </Box>
  )
}

export function PermissionView({ permission }: { permission: Permission }) {
  return (
    <>
      <Grid container>
        {permission.read && <Grid size={4}>{permissionLabels.read}</Grid>}
        {permission.write && <Grid size={4}>{permissionLabels.write}</Grid>}
        {permission.delete && <Grid size={4}>{permissionLabels.delete}</Grid>}
        {permission.canViewUserData && <Grid size={4}>{permissionLabels.canViewUserData}</Grid>}
        {permission.canViewPaymentData && <Grid size={4}>{permissionLabels.canViewPaymentData}</Grid>}
      </Grid>
    </>
  )
}

export const formFieldsConfig = {
  email: {
    placeholder: 'Email',
    label: 'Email',
    name: 'email',
    type: 'email',
  },
  name: {
    placeholder: 'Name',
    label: 'Name',
    name: 'name',
  },
}

export const validationSchema = object().shape({
  email: string().email().required('Please enter email id.'),
  name: string().required('Please enter User name'),
})
