/* eslint-disable sonarjs/deprecation */
import { Box, Grid, Typography, SxProps } from '@mui/material'
import {
  SvgIconComponent,
  TripOrigin as TripOriginIcon,
  LocalAirport as LocalAirportIcon,
  AccessTime as AccessTimeIcon,
  CalendarMonth as CalendarMonthIcon,
  Airlines as AirlinesIcon,
  AirlineStops as AirlineStopsIcon,
  FlightClass as FlightClassIcon,
  Numbers as NumbersIcon,
  Class as ClassIcon,
  ConnectingAirports as ConnectingAirportsIcon,
} from '@mui/icons-material'
import { fontWeight } from 'styles/theme'
import { Tooltip } from 'components/UI'
import { TextField } from 'components/Forms'
import WidgetCard from './WidgetCard'
import { WheelEventHandler } from 'react'
import { hasValue } from 'helpers/Methods'

interface FlightWidgetStateProps {
  title?: string
  Icon?: SvgIconComponent
  flightInfo?: {
    flightNumber?: string
    flightClass?: string
    stops?: string
    farebasiscode?: string
    fromConnectionCity1?: string
    fromConnectionCity2?: string
    class_type?: string
    departureAriport: string
    arrivalAirport: string
    departDate: string
    departTime: string
    arrivalDate: string
    arrivalTime: string
    departureLocation: string
    arrivalLocation: string
    operatedBy: string
    seats: string
    travelTime: number
  }
  editState?: boolean
  product?: string
  onChangeValue?(product: string, field: string, value: string | number): void
  componentWrapperStyles?: SxProps
}

const numberInputOnWheelPreventChange = (event) => {
  // Prevent the input value change
  event.target.blur()

  // Prevent the page/container scrolling
  event.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    event.target.focus()
  }, 0)
}

export default function FlightWidget({
  title,
  Icon,
  flightInfo,
  editState = false,
  onChangeValue,
  product,
}: FlightWidgetStateProps) {
  return (
    <>
      <WidgetCard
        title={title}
        Icon={Icon}
        componentWrapperStyles={{
          border: 'none',
        }}
      />
      {hasValue(flightInfo.operatedBy) &&
        (editState ? (
          <TextField
            type="text"
            name="operatedBy"
            value={flightInfo.operatedBy}
            onChange={(event) => {
              onChangeValue(product, 'operatedBy', event.target.value)
            }}
            size="small"
            onWheel={numberInputOnWheelPreventChange}
            sx={{ width: '50%', mb: '4px' }}
          />
        ) : (
          <WidgetCard
            title={flightInfo.operatedBy}
            Icon={AirlinesIcon}
            componentWrapperStyles={{
              border: 'none',
            }}
          />
        ))}

      {editState && (
        <>
          <WidgetSection
            text={flightInfo.flightNumber}
            Icon={TripOriginIcon}
            typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
            editState={editState}
            product={product}
            inputField="flightNumber"
            inputWidth="50%"
            inputType="text"
            onChangeValue={onChangeValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />
          <WidgetSection
            text={flightInfo.flightClass}
            Icon={TripOriginIcon}
            typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
            editState={editState}
            product={product}
            inputField="flightClass"
            inputWidth="50%"
            inputType="text"
            onChangeValue={onChangeValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />
          <WidgetSection
            text={flightInfo.stops ?? 0}
            Icon={TripOriginIcon}
            typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
            editState={editState}
            product={product}
            inputField="stops"
            inputWidth="50%"
            inputType="number"
            onChangeValue={onChangeValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />
          <WidgetSection
            text={flightInfo.class_type}
            Icon={TripOriginIcon}
            typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
            editState={editState}
            product={product}
            inputField="class_type"
            inputWidth="50%"
            inputType="number"
            onChangeValue={onChangeValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />
          <WidgetSection
            text={flightInfo.farebasiscode}
            Icon={TripOriginIcon}
            typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
            editState={editState}
            product={product}
            inputField="farebasiscode"
            inputWidth="50%"
            inputType="number"
            onChangeValue={onChangeValue}
            numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
          />
        </>
      )}

      <Tooltip
        title={
          <>
            {flightInfo?.operatedBy !== '' && (
              <p>
                <AirlinesIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Operated By: {flightInfo?.operatedBy}
              </p>
            )}
            {flightInfo?.flightNumber !== '' && (
              <p>
                <NumbersIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Flight#: {flightInfo?.flightNumber}
              </p>
            )}
            {flightInfo?.flightClass !== '' && (
              <p>
                <FlightClassIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Class of Service: {flightInfo?.flightClass}
              </p>
            )}
            <p>
              <AirlineStopsIcon sx={FlightWidgetStyles.tooltipValueIcon} />
              Stops: {flightInfo?.stops === '' ? '0' : flightInfo?.stops}
            </p>
            {flightInfo?.class_type !== '' && (
              <p>
                <ClassIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Class Type: {flightInfo?.class_type}
              </p>
            )}
            {flightInfo?.farebasiscode !== '' && (
              <p>
                <NumbersIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                FairBasic Code: {flightInfo?.farebasiscode}
              </p>
            )}
            {flightInfo?.fromConnectionCity1 !== '' && (
              <p>
                <ConnectingAirportsIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Connecting City 1: {flightInfo?.fromConnectionCity1}
              </p>
            )}
            {flightInfo?.fromConnectionCity2 !== '' && (
              <p>
                <ConnectingAirportsIcon sx={FlightWidgetStyles.tooltipValueIcon} />
                Connecting City 2: {flightInfo?.fromConnectionCity2}
              </p>
            )}
          </>
        }
        disableFocusListener
        disableTouchListener
      >
        <Grid container xs={12} md={12} sx={{ borderBottom: '1px solid #dbd3d3', pb: '15px' }}>
          <Grid item xs={5}>
            <Box sx={FlightWidgetStyles.fromContainerStyle}>
              <WidgetSection
                text={flightInfo.departureLocation}
                Icon={TripOriginIcon}
                typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
                editState={editState}
                product={product}
                inputField="departureLocation"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.departureAriport}
                Icon={LocalAirportIcon}
                editState={editState}
                product={product}
                inputField="departureAriport"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.departTime}
                Icon={AccessTimeIcon}
                editState={editState}
                product={product}
                inputField="departTime"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.departDate}
                Icon={CalendarMonthIcon}
                editState={editState}
                product={product}
                inputField="departDate"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
            </Box>
          </Grid>
          <Grid sx={FlightWidgetStyles.durationSectionStyle as SxProps} item xs={2}>
            <Box sx={{}}>
              <Typography sx={{ textAlign: 'center', fontSize: '0.8rem' }}>
                To <br />
                {`${Math.floor(flightInfo.travelTime / 60)}h ${flightInfo.travelTime % 60}m`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box sx={FlightWidgetStyles.toContainerStyle}>
              <WidgetSection
                text={flightInfo.arrivalLocation}
                Icon={TripOriginIcon}
                typographyStylesWrapper={{ fontWeight: fontWeight.bold }}
                editState={editState}
                product={product}
                inputField="arrivalLocation"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.arrivalAirport}
                Icon={LocalAirportIcon}
                editState={editState}
                product={product}
                inputField="arrivalAirport"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.arrivalTime}
                Icon={AccessTimeIcon}
                editState={editState}
                product={product}
                inputField="arrivalTime"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
              <WidgetSection
                text={flightInfo.arrivalDate}
                Icon={CalendarMonthIcon}
                editState={editState}
                product={product}
                inputField="arrivalDate"
                inputType="text"
                onChangeValue={onChangeValue}
                numberInputOnWheelPreventChange={numberInputOnWheelPreventChange}
                inputWidth="100%"
              />
            </Box>
          </Grid>
        </Grid>
      </Tooltip>
    </>
  )
}

interface WidgetStateProps {
  text?: string | number
  Icon?: SvgIconComponent
  typographyStylesWrapper?: SxProps
  editState?: boolean
  inputType?: string
  inputField?: string
  product?: string
  inputWidth?: string
  onChangeValue?(product: string, field: string, value: string | number): void
  numberInputOnWheelPreventChange?: WheelEventHandler<HTMLDivElement>
}

function WidgetSection({
  text,
  Icon,
  typographyStylesWrapper,
  editState = false,
  inputField,
  onChangeValue,
  numberInputOnWheelPreventChange,
  product,
  inputWidth,
}: WidgetStateProps) {
  return (
    <Box>
      {editState ? (
        <TextField
          type="text"
          name={inputField}
          value={text}
          onChange={(event) => {
            onChangeValue(product, inputField, event.target.value)
          }}
          size="small"
          onWheel={numberInputOnWheelPreventChange}
          sx={{ width: inputWidth, my: '4px' }}
        />
      ) : (
        <Typography
          sx={
            {
              ...FlightWidgetStyles.typographyStyle,
              ...typographyStylesWrapper,
            } as SxProps
          }
        >
          <Icon sx={FlightWidgetStyles.iconStyle} />
          {text}
        </Typography>
      )}
    </Box>
  )
}

interface FlightWidgetStylesType {
  fromContainerStyle: SxProps
  toContainerStyle: SxProps
  typographyStyle: SxProps
  iconStyle: SxProps
  durationSectionStyle: SxProps
  tooltipValueIcon: SxProps
}

const FlightWidgetStyles: FlightWidgetStylesType = {
  fromContainerStyle: {
    fontSize: '0.8rem',
    float: 'left',
    width: '100%',
  },
  iconStyle: { fontSize: '0.8rem', marginRight: '5px' },
  durationSectionStyle: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    padding: '15px',
    justifyContent: 'center',
  },
  typographyStyle: {
    fontSize: '0.8rem',
    py: '4px',
    // fontWeight: fontWeight.bold,
  },
  toContainerStyle: {
    fontSize: '0.8rem',
    // float: 'right',
  },
  tooltipValueIcon: {
    fontSize: '0.95rem',
    paddingTop: '5px',
  },
}
