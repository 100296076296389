import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { dashboardResponse, dashboardAnalyticsResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { DashboardAnalyticsResponse } from './dahsboard.types'

const dashboard = (data = {}) => (SHOULD_MOCK_APIS ? apiHandler.mockApi(dashboardResponse) : apiHandler.postRequest('dashboard/graph-data', {data}))

const dashboardAnalytics = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<DashboardAnalyticsResponse>(dashboardAnalyticsResponse) : apiHandler.postRequest<DashboardAnalyticsResponse>('dashboard/analytics', {data})

const dashboardService = {
  dashboard,
  dashboardAnalytics,
}

export default dashboardService
