import { Box, SxProps } from '@mui/material'
import { PieChart, ChartWidgetWrapper } from 'components/UI'
import { ChartWidget } from './ChartWidgetWrapper'

export default function PieChartWidget({ title, ...otherProps }: ChartWidget) {
  return (
    <ChartWidgetWrapper title={title}>
      <Box
        sx={
          [
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            },
          ] as SxProps
        }
      >
        <PieChart chartWrapperProps={{ sx: { width: '250px' } }} {...otherProps} />
      </Box>
    </ChartWidgetWrapper>
  )
}
