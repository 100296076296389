import { format } from 'date-fns'
import { API_DATE_FORMAT, READABLE_DATE_FORMAT } from 'helpers/Constants'

/**
 * This methods return formatted date for given format
 * @param date date to transform
 * @param dateFormat date format
 * @returns
 */
export const formatDate = (date: Date, dateFormat: string) => format(date, dateFormat)

/**
 * This methods return formatted date for api
 * @param date date to transform
 * @returns formatted date
 */
export const formatDateForApi = (date: Date) => format(date, API_DATE_FORMAT)

/**
 * This methods return readable date to display in UI
 * @param date date to transform
 * @returns formatted date
 */
export const readableDate = (date?: string | Date) => (date ? format(date, READABLE_DATE_FORMAT) : '')
