import { Box, InputAdornment, SxProps } from '@mui/material'
import { TextField } from 'components/Forms'
import { Card, Chip, DataGrid, GridColDef, PageTitle } from 'components/UI'
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material'
import { useCallback, useEffect, useState } from 'react'
import { newsLetterSubscribersService } from 'services'
import { useDebounce } from 'use-debounce'
import { useGridApiRef } from '@mui/x-data-grid'
import { NewsLetterSubscribersData } from 'services/newsLetterSubscribers.types'
import useToast from 'components/UI/Toast'
import { readableDate } from 'helpers/Methods'

export default function NewsLetterSubscribers() {
  const [newsLetterSubscribersList, setNewsLetterSubscribersList] = useState([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [debouncedQuery] = useDebounce(searchKeyword, 500)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()
  const [count, setCount] = useState(0)
  const gridApiRef = useGridApiRef()

  const fetchNewsLetterSubscribers = useCallback(
    async ({ currentPage = 1 }) => {
      setIsLoading(true)
      try {
        const { response } = await newsLetterSubscribersService.newsLetterSubscribers({
          currentPage,
          search: debouncedQuery,
        })
        setNewsLetterSubscribersList(response.data.newsLetters)
        setCount(response.data.count)
      } catch (error) {
        toast.error(error?.data?.message)
      } finally {
        setIsLoading(false)
      }
    },
    [debouncedQuery, toast],
  )

  useEffect(() => {
    fetchNewsLetterSubscribers({ currentPage })
  }, [currentPage, fetchNewsLetterSubscribers])

  const columns: GridColDef<NewsLetterSubscribersData>[] = [
    {
      headerName: 'Name',
      field: 'name',
    },
    { headerName: 'Email', field: 'email' },
    {
      headerName: 'Subscribed',
      field: 'subscribed',
      renderCell: (value) => (
        <Chip size="small" label={value ? 'Yes' : 'No'} color={value ? 'success' : 'error'} borderLess />
      ),
    },
    {
      headerName: 'Subscribed Date',
      field: 'created_at',
      valueFormatter: (value) => {
        return readableDate(value)
      },
    },
    // {
    //   headerName: 'Actions',
    //   field: 'id',
    //   renderCell: ({ value }) => (
    //     <>
    //       {permission.read && (
    //         <IconButton
    //           onClick={() => {
    //             navigate(`${VIEW_SUBPATH}?id=${value}`)
    //           }}
    //         >
    //           <ViewIcon />
    //         </IconButton>
    //       )}
    //     </>
    //   ),
    // },
  ]

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={newsLetterSubscribersStyles.titleWrapper}>
          <Box sx={newsLetterSubscribersStyles.searchWrapper}>
            <TextField
              placeholder="Search NewsLetter Subscriber"
              value={searchKeyword}
              onChange={(event) => {
                setSearchKeyword(event.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                searchKeyword && (
                  <InputAdornment position="end">
                    <ClearIcon onClick={() => setSearchKeyword('')} sx={{ cursor: 'pointer' }} />
                  </InputAdornment>
                )
              }
              fullWidth
            />
          </Box>

          <Box sx={newsLetterSubscribersStyles.toolsWrapper}></Box>
        </Box>

        <Box sx={newsLetterSubscribersStyles.tableWrapper}>
          <DataGrid
            apiRef={gridApiRef}
            rows={newsLetterSubscribersList}
            columns={columns}
            rowCount={count}
            onPaginationModelChange={(data) => {
              setCurrentPage(data.page + 1)
              fetchNewsLetterSubscribers({ currentPage: data.page + 1 })
            }}
            loading={isLoading}
          />
        </Box>
      </Card>
    </Box>
  )
}

interface NewsLetterSubscribersStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

const newsLetterSubscribersStyles: NewsLetterSubscribersStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchWrapper: { width: 300 },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'NewsLetter Subscribers',
}
