/* eslint-disable sonarjs/deprecation */
import {
  Box,
  Grid,
  SxProps, FormControl, SelectChangeEvent
} from '@mui/material'
import {
  ColumnChartWidget,
  PageTitle,
  PieChartWidget,
  ScatterChartWidget,
  SeriesData,
  SplineChartWidget,
  TopXList,
} from 'components/UI'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ObjectOf } from 'helpers/Types'
import customerAnalytics from 'services/customerAnalytics.service'
import { CalendarDateRangeType } from 'components/Forms/DateRangePicker'
import { subMonths } from 'date-fns'
import { DateRangePicker, IconButton, MultiSelect } from 'components/Forms'
import { COLORS } from 'styles'
import { Tune as TuneIcon } from '@mui/icons-material'
import { selectChartSettings } from 'redux/CustomerAnalytics/customerAnalytics.selectors'
import { useAppSelector } from 'redux/hooks'
import { formatDateForApi } from 'helpers/Methods'
import { CHART_TYPES } from 'helpers/Constants'
import TopStats from './TopStats'
import ChartSettings from './ChartSettings'
import { selectUser } from 'redux/Auth/auth.selectors'
import { MultiSelectItem } from 'components/Forms/MultiSelect/MultiSelect'

export interface GraphData {
  xAxisLabels: string[]
  series: SeriesData[]
}

const defaultChartData = {
  xAxisLabels: [],
  series: [],
}

export default function CustomerAnalytics() {
  const [topStats, setTopStats] = useState({})
  const [graphData, setGraphData] = useState<ObjectOf<GraphData>>({})
  const [dateRange, setDateRange] = useState<CalendarDateRangeType>([subMonths(new Date(), 1), new Date()])
  const [chartSettingOpen, setChartSettingOpen] = useState(false)
  const chartSettings = useAppSelector(selectChartSettings)
  const [charts, setCharts] = useState([])
  const userDomains = useAppSelector(selectUser)?.userDomains
  const initDomainValues = useMemo(() => userDomains?.map((domain) => domain.domain.id) , [userDomains])
  const [domains, setDomains] = useState<number[]>(initDomainValues)

  const handleDomainChange = useCallback((event: SelectChangeEvent<typeof domains>) => {
    const {
      target: { value },
    } = event

    if (value.at(-1) === "all") {
      setDomains(domains.length === userDomains.length ? [] : initDomainValues)
      return
    }

    setDomains(value as number[])
  }, [domains.length, initDomainValues, userDomains.length])

  const getData = useCallback(async () => {
    const {
      response: {
        data: { stats, chart },
      },
    } = await customerAnalytics.getAnalyticsData({
      params: {
        startDate: formatDateForApi(dateRange[0]),
        endDate: formatDateForApi(dateRange[1]),
        domains: domains.join(',') || initDomainValues.join(',')
      },
    })
    setTopStats({ ...stats })
    setGraphData({
      ...chart,
    })
  }, [dateRange, domains, initDomainValues])

  // fetch data
  useEffect(() => {
    getData()
  }, [getData, dateRange, domains])

  // chart config
  useEffect(() => {
    setCharts(
      chartSettings
        .filter((chart) => !!chart.visible)
        .map((chart) => {
          let Component

          switch (chart.type) {
            case CHART_TYPES.COLUMN: {
              Component = ColumnChartWidget
              break
            }

            case CHART_TYPES.PIE: {
              Component = PieChartWidget
              break
            }

            case CHART_TYPES.SCATTER: {
              Component = ScatterChartWidget
              break
            }

            case CHART_TYPES.SPLINE: {
              Component = SplineChartWidget
              break
            }

            case CHART_TYPES.TOP_X_LIST: {
              Component = TopXList
              break
            }

            default: {
              Component = ColumnChartWidget
              break
            }
          }

          return {
            ...chart,
            Component,
          }
        }),
    )
  }, [chartSettings])

  return (
    <Box>
      {/* Heading */}
      <Box sx={customerAnalyticsStyles.pageHeaderWrapper}>
        <PageTitle>Customer Analytics</PageTitle>

        {/* Toolbar */}
        <Box sx={customerAnalyticsStyles.filterWrapper}>
          <Box sx={{ ...customerAnalyticsStyles.toolsWrapper, mr: '5px' }}>
            <FormControl sx={{ width: { xs:"100%", sm: 250 } }} size="small">
              <MultiSelect
                  value={domains}
                  onChange={handleDomainChange}
                  renderValue={(selected: number[]) =>
                    selected.map((id) => userDomains.find((domain) => domain?.domain.id === id)?.domain.name).join(', ')
                  }
                  total={userDomains?.length}
                  title='Domains'
                >
                  {userDomains?.map((domain) => (
                    <MultiSelectItem key={domain.domain.id} label={domain.domain.name} value={domain.domain.id} checked={domains.includes(domain.domain.id)} />
                  ))}
                </MultiSelect>
            </FormControl>
          </Box>

          <Box sx={customerAnalyticsStyles.toolsWrapper}>
            <DateRangePicker
              value={dateRange}
              onChange={(range) => {
                setDateRange(range)
              }}
              disableFutureDates
              // displayValue
              customDisplayCalendarIcon
              fullWidth
            />
            <IconButton
              sx={customerAnalyticsStyles.toolIconButton}
              onClick={() => {
                setChartSettingOpen(true)
              }}
            >
              <TuneIcon sx={customerAnalyticsStyles.toolIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Charts */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopStats data={topStats} />
        </Grid>
        {charts.map((chart) => (
          <Grid item xs={12} sm={12} md={6} key={chart.dataKey}>
            <chart.Component {...chart.props} {...(graphData[chart.dataKey] ?? { ...defaultChartData })} />
          </Grid>
        ))}
      </Grid>

      <ChartSettings
        open={chartSettingOpen}
        onClose={() => {
          setChartSettingOpen(false)
        }}
      />
    </Box>
  )
}

interface CustomerAnalyticsStyles {
  pageHeaderWrapper: SxProps
  filterWrapper: SxProps
  toolsWrapper: SxProps
  toolIconButton: SxProps
  toolIcon: SxProps
}

const customerAnalyticsStyles: CustomerAnalyticsStyles = {
  pageHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: '10px', sm: 0 },
  },
  filterWrapper: {
    display: 'inline-flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: '10px', sm: 0 },
  },
  toolsWrapper: {
    display: 'flex',
    padding: '0 10px',
    border: `1px solid ${COLORS.CADET_BLUE}`,
    borderRadius: '50px',
    minHeight: '42px',
  },
  toolIconButton: {
    p: 0.5,
  },
  toolIcon: {
    color: COLORS.BLACK,
    '&:hover': { color: COLORS.PRIMARY_DARK },
  },
}
