import { Domains, Modules } from 'helpers/Constants'

export interface AuthState {
  token: string | null
  user?: User | null
}

export const initialState: AuthState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ?? '',
}

export interface User {
  id: number
  email: string
  name: string
  userDomains: UserDomain[]
  permissions: Permission[]
  createdAt: string
}

export interface Domain {
  id: number
  name: Domains
  color: string
  createdAt: string
  updatedAt: string
}

export interface BookingStatus {
  id: number
  status: string
}

export interface UserDomain {
  id: number
  domain: Domain
  createdAt: string
  updatedAt: string
}

export interface Module {
  id: number
  name: Modules
  createdAt: string
  updatedAt: string
}

export interface PermissionTypes {
  read: boolean
  write: boolean
  delete: boolean
  canViewPaymentData: boolean
  canViewUserData: boolean
}

export interface Permission extends PermissionTypes {
  id: number
  userId: number
  module: Module
  moduleId: 1
  createdAt: string
  updatedAt: string
}

export interface ModulePermission extends PermissionTypes {
  module: Module
}
