import { Box, Typography } from '@mui/material'
import WDateRangePicker from '@wojtekmaj/react-daterange-picker'
import {
  LooseValue,
  ClassName,
  Value,
  Detail,
} from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types'
import { addDays } from 'date-fns'
import { readableDate } from 'helpers/Methods'
import { useState } from 'react'
import { COLORS } from 'styles'

/**
 * Base Types are imported from package except documented once
 */
export interface DateRangePickerProps {
  calendarIcon?: JSX.Element
  autoFocus?: boolean
  calendarAriaLabel?: string
  calendarClassName?: ClassName
  className?: ClassName
  clearAriaLabel?: string
  closeCalendar?: boolean
  'data-testid'?: string
  dayAriaLabel?: string
  dayPlaceholder?: string
  disableCalendar?: boolean
  wrapperClass?: string
  /**
   * this flag disables future date
   * @default false
   */
  disableFutureDates?: boolean

  disabled?: boolean
  format?: string
  id?: string
  locale?: string
  maxDate?: Date
  maxDetail?: Detail
  minDate?: Date
  monthAriaLabel?: string
  monthPlaceholder?: string
  name?: string
  nativeInputAriaLabel?: string
  onCalendarClose?: () => void
  onCalendarOpen?: () => void

  /**
   * Callback is fired when user changes date range
   */
  onChange?: (value: Value) => void
  onFocus?: (event: React.FocusEvent<HTMLDivElement>) => void
  openCalendarOnFocus?: boolean
  portalContainer?: HTMLElement | null
  rangeDivider?: React.ReactNode
  required?: boolean
  showLeadingZeros?: boolean
  value?: LooseValue

  /**
   * this flag displays label along with picker
   * @default false
   */
  displayValue?: boolean
  customDisplayCalendarIcon?: boolean
  yearAriaLabel?: string
  yearPlaceholder?: string
  fullWidth?: boolean
}

export default function DateRangePicker({
  onCalendarClose,
  disableFutureDates,
  displayValue = false,
  customDisplayCalendarIcon = false,
  wrapperClass= '',
  fullWidth,
  ...props
}: DateRangePickerProps) {
  const [isOpen, setIsOpen] = useState(false)

  const closeHandler = () => {
    if (onCalendarClose) onCalendarClose()

    setIsOpen(false)
  }

  const modifiedProps: DateRangePickerProps = {}

  if (disableFutureDates) modifiedProps.maxDate = addDays(new Date(), 1)
  if (customDisplayCalendarIcon) {
    modifiedProps.calendarIcon = (
      <Typography
        sx={{
          fontSize: 14,
          cursor: 'pointer',
          '&:hover': {
            color: COLORS.PRIMARY_DARK,
          },
        }}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {readableDate(props.value[0])} to {readableDate(props.value[1])}
      </Typography>
    )
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      className={`date-range-picker ${fullWidth ? 'full-calendar' : ''} ${wrapperClass}`}
    >
      {displayValue && (
        <Typography
          sx={{
            fontSize: 14,
            cursor: 'pointer',
            '&:hover': {
              color: COLORS.PRIMARY_DARK,
            },
          }}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {readableDate(props.value[0])} to {readableDate(props.value[1])}
        </Typography>
      )}
      <WDateRangePicker
        onCalendarClose={closeHandler}
        isOpen={isOpen}
        {...modifiedProps}
        {...props}
      />
    </Box>
  )
}



export {type LooseValue as CalendarDateRangeType, type ClassName, type Value, type Detail} from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types'