import { RightSideBar, RightSidebarProps } from 'components/Navigation/Sidebar'
import { componentsStyles as styles } from '../../CRMUsers/__components__/Components.styles'
import { Box, Typography } from '@mui/material'
import { MailIcon } from 'components/UI/Icons'
import { PromoCodeResponse } from 'services/promoCode.types'

interface ViewPromoCodeProps extends Omit<RightSidebarProps, 'headerText'> {
  data: PromoCodeResponse
}

export default function ViewPromoCode({ data, ...props }: ViewPromoCodeProps) {
  if (!data) return
  return (
    <RightSideBar headerText={data.promo_code} bodyStyles={styles.wrapper} {...props}>
      <Box sx={styles.userEmail}>
        <MailIcon sx={styles.emailIcon} />
        <Typography>{data.promo_code}</Typography>
      </Box>
      <Typography sx={styles.sectionHeading}>{data.percent_amount}</Typography>
    
    </RightSideBar>
  )
}
