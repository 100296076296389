import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { userListResponse, userDetailsResponse, exportUsersCSVResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'

export interface User {
  ID: number
  Email: string
  loginProvider: string
  TitleName: string
  FirstName: string
  MiddleName: string
  LastName: string
  DOB: string
  primaryPhone: string
  CreatedDate: string
}

export interface UserWithAddress extends User {
  AddressLine1: string
  AddressLine2: string
  City: string
  PostalCode: string
  Province: string
  Country: string
}

export interface UserListResponse {
  users: User[]
  count: number
}
export interface UserExportResponse {
  users: User[]
  columns: string[]
}

const users = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<UserListResponse>(userListResponse)
    : apiHandler.postRequest<UserListResponse>('customer/list', data)

const userDetails = (userId: string) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<UserWithAddress>(userDetailsResponse)
    : apiHandler.getRequest<UserWithAddress>(`customer/${userId}`)

const exportUsers = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<UserExportResponse>(exportUsersCSVResponse)
    : apiHandler.getRequest<UserExportResponse>('customer/export', data)

const usersService = {
  users,
  userDetails,
  exportUsers,
}

export default usersService
