import { Box, Drawer, DrawerProps, SxProps, Typography } from '@mui/material'
import Button, { ButtonProps, IconButton } from 'components/Forms/Button'
import { fontWeight } from 'styles/theme'
import { Close as CloseIcon } from '@mui/icons-material'
import { COLORS } from 'styles'

export interface RightSidebarProps extends DrawerProps {
  /**
   * Modal title
   */
  headerText: string
  /**
   * additional buttons that will show up at bottom
   */
  footerActions?: FooterAction[]
  onClose: () => void

  /**
   * additional body styles
   */
  bodyStyles?: SxProps
}

interface FooterAction extends ButtonProps {
  /**
   * button label
   */
  label: string
}

const SIZES = {
  header: 60,
  footer: 75,
}

export default function RightSidebar({
  onClose,
  children,
  headerText,
  footerActions = [],
  bodyStyles = {},
  ...otherProps
}: RightSidebarProps) {
  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      sx={{ '.MuiPaper-root': { overflowY: 'hidden' }, zIndex: 1201 }}
      {...otherProps}
    >
      <Box
        sx={{
          minWidth: Math.min(350, window.innerWidth),
          height: '100%',
        }}
      >
        {/* Header */}
        <Box
          sx={{
            height: SIZES.header,
            p: 1.5,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: `1px solid ${COLORS.BRIGHT_GRAY}`,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: fontWeight.medium,
            }}
          >
            {headerText}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Body */}
        <Box
          sx={
            [
              {
                height: `calc(100svh - ${SIZES.header}px - ${SIZES.footer}px)`,
                py: 1.5,
                px: 2.5,
                overflowY: 'auto',
              },
              bodyStyles,
            ] as SxProps
          }
        >
          {children}
        </Box>

        {/* Footer */}
        <Box
          sx={{
            height: SIZES.footer,
            p: 1.5,
            display: 'flex',
            boxShadow:
              '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {footerActions.map((action) => (
              <Button sx={{ mx: 0.5 }} {...action} key={action.label}>
                {action.label}
              </Button>
            ))}
            <Button sx={{ mx: 0.5 }} variant="text" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
