import { RightSidebarProps } from 'components/Navigation/Sidebar'
import useToast from 'components/UI/Toast'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import { validationSchema } from './Components'
import { promoCodeService } from 'services'
import AddPromoCodeDrawer from './AddPromoCodeDrawer'
import { PromoCodeResponse } from 'services/promoCode.types'

interface IEditPromoCoderProps extends Omit<RightSidebarProps, 'headerText'> {
  data: PromoCodeResponse
  onEditSuccess: (promoCode: PromoCodeResponse) => void
}

export default function EditPromoCode({ data, onEditSuccess, ...props }: IEditPromoCoderProps) {
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: { promo_code: data.promo_code,
         percent_amount: data.percent_amount,
         flat_amount : data.flat_amount,
         min_amount : data.min_amount,
         max_amount : data.max_amount,
         multi_use : data.multi_use,
         single_use : data.single_use,
         code_start_date : data.code_start_date,
         code_end_date : data.code_end_date,
        },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {},
    validateOnMount: true,
  })

  const onSave = useCallback(async () => {
    if (!formik.isValid) {
      formik.submitForm()
      return
    }

    setLoading(true)
    const { values } = formik
    console.log(values)
    try {
        await promoCodeService.updatePromoCode(data.id, {
            ...values,
            single_use: values.multi_use === 1 ? 0 : 1
          })
    
          const { response } = await promoCodeService.getPromoCode(data.id)
          onEditSuccess(response.data)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [data.id, formik, onEditSuccess, toast])

  // if (!data) return

   return (
      <AddPromoCodeDrawer
        headerText={data.promo_code}
        loading={loading}
        onSave={onSave}
        formik={formik}
        {...props}
      />
    )
}
