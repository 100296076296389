import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { userListResponse, userDetailsResponse, crmUserDetails } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { User } from 'redux/Auth/auth.state'
import { UpdateCrmUserRequest, UserListParams, UserListResponse } from './crmUsers.types'

const crmUsers = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(userListResponse) : apiHandler.getRequest('crmUsers', data)

const userDetails = (data = {}) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi(userDetailsResponse) : apiHandler.getRequest('userDetails', data)

const getUser = (userId: number) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<User>(crmUserDetails) : apiHandler.getRequest<User>(`crm-users/${userId}`)

const addUser = (data: UpdateCrmUserRequest) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.postRequest<{}>(`crm-users`, { data })

const updateUser = (userId: number, data: UpdateCrmUserRequest) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.putRequest<{}>(`crm-users/${userId}`, { data })

const deleteUser = (userId: number) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.deleteRequest<{}>(`crm-users/${userId}`)

const crmUserList = (data: UserListParams) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<UserListResponse>(crmUserList)
    : apiHandler.postRequest<UserListResponse>(`crm-users/list`, { data })

const crmUsersService = {
  crmUsers,
  userDetails,
  getUser,
  crmUserList,
  addUser,
  updateUser,
  deleteUser,
}

export default crmUsersService
