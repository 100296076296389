import { CSSObject, SxProps, Theme } from '@mui/material'
import { DRAWER_WIDTH, HEADER_HEIGHT } from 'helpers/Constants'
import { COLORS } from 'styles'

interface SidebarStylesTypes {
  drawer: SxProps
  drawerOpen: SxProps
  drawerClose: SxProps
  drawerHeader: SxProps
  sidebarItemWrapper: SxProps
  sidebarItem: SxProps
  sidebarItemButton: SxProps
  sidebarItemButtonActive: SxProps
  sidebarItemButtonOpen: SxProps
  sidebarItemButtonOpenActive: SxProps
  sidebarItemIconWrapper: SxProps
  sidebarItemIcon: SxProps
  sidebarItemLink: SxProps
  sidebarItemTextActive: SxProps
}

const sidebarStyles: SidebarStylesTypes = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      backgroundColor: COLORS.WHITE,
      boxShadow: 'rgba(47, 43, 61, 0.12) 0px 2px 8px 0px',
      border: 0,
    },
  },
  drawerOpen: (theme: Theme): CSSObject => ({
    ...openedDrawerStyles(theme),
    '& .MuiDrawer-paper': openedDrawerStyles(theme),
  }),
  drawerClose: (theme: Theme): CSSObject => ({
    ...closeDrawerStyles(theme),
    '& .MuiDrawer-paper': closeDrawerStyles(theme),
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingY: 1,
    height: HEADER_HEIGHT,
  },

  sidebarItemWrapper: {},
  sidebarItem: { display: 'block', paddingY: 0.5 },
  sidebarItemButton: {
    minHeight: 48,
    justifyContent: 'center',
    px: 0,
    borderRadius: '5px',
    mx: .5,
  },
  sidebarItemButtonActive: {
    background: `linear-gradient(270deg, #4bbbf9, ${COLORS.HIGHLIGHT_BLUE} 100%)`,
    boxShadow: 'rgba(0, 158, 247, 0.3) 0px 2px 6px 0px',
  },
  sidebarItemButtonOpen: {
    justifyContent: 'initial',
    px: 1,
    mx: 2,
  },
  sidebarItemButtonOpenActive: {},
  sidebarItemIconWrapper: {
    justifyContent: 'center',
  },
  sidebarItemIcon: {
    color: 'rgba(47, 43, 61, 0.9)',
  },
  sidebarItemLink: {
    color: 'rgba(47, 43, 61, 0.9)',
  },
  sidebarItemTextActive: {
    color: COLORS.WHITE,
  },
}

const openedDrawerStyles = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closeDrawerStyles = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

export default sidebarStyles
