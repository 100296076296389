/* eslint-disable unicorn/filename-case */
import { Avatar, Box, IconButton } from '@mui/material'
import { Card, DataGrid, Dialog, GridColDef, PageTitle } from 'components/UI'
import { usersStyles } from 'pages/Users/UserList'
import { useCrmUserListData } from './CRMUsers.controller'
import { usersListStyles } from './CrmUsers.styles'
import { usePermission } from 'components/Core/Permissions'
import { useCallback, useMemo, useState } from 'react'
import { MODULES } from 'helpers/Constants'
import { User } from 'redux/Auth/auth.state'
import ViewCrmUser from './__components__/ViewCrmUser'
import { AddIcon, DeleteIcon, EditIcon, ViewIcon } from 'components/UI/Icons'
import EditCrmUser from './__components__/EditCrmUser'
import { useAppSelector } from 'redux/hooks'
import { selectUser } from 'redux/Auth/auth.selectors'
import { crmUsersService } from 'services'
import useToast from 'components/UI/Toast'
import { useGridApiRef } from '@mui/x-data-grid'
import { Button } from 'components/Forms'
import AddCrmUser from './__components__/AddCrmUser'

export default function CRMUsers() {
  const { list, setList, count, getUserList, isLoading } = useCrmUserListData()
  const [currentPage, setCurrentPage] = useState(1)
  const { id: currentUserId } = useAppSelector(selectUser)
  const { getPermission } = usePermission()
  const [addUser, setAddUser] = useState(false)
  const [viewUser, setViewUser] = useState<User | undefined>()
  const [editUser, setEditUser] = useState<User | undefined>()
  const [deleteUser, setDeleteUser] = useState<User | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)
  const toast = useToast()
  const gridApiRef = useGridApiRef()

  const permission = useMemo(() => getPermission(MODULES.CRM_USERS), [getPermission])

  const onAddSuccess = useCallback(() => {
    getUserList({ currentPage })
  }, [currentPage, getUserList])

  const onEditSuccess = useCallback(
    (user: User) => {
      const newList = [...list]

      newList.forEach((element, index) => {
        if (element.id === user.id) newList[index] = { ...user }
      })

      setList(newList)
      setEditUser(undefined)
    },
    [list, setList],
  )

  const onDelete = useCallback(async () => {
    setIsDeleting(true)
    try {
      await crmUsersService.deleteUser(deleteUser.id)
      getUserList({ currentPage })
      setDeleteUser(undefined)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setIsDeleting(false)
    }
  }, [currentPage, deleteUser?.id, getUserList, toast])

  const columns: GridColDef<User>[] = [
    { headerName: 'Name', field: 'name' },
    { headerName: 'Email', field: 'email' },
    {
      headerName: 'Domains',
      field: 'userDomains',
      renderCell: ({ value }) => (
        <Box className="f-center full-h" sx={usersListStyles.domainAvatarWrapper}>
          {value.map((domain) => (
            <Avatar
              key={domain.id}
              sx={{ backgroundColor: domain.domain.color, ...usersListStyles.domainAvatar }}
              title={domain.domain.name}
            >
              {domain.domain.name.slice(0, 1)}
            </Avatar>
          ))}
        </Box>
      ),
    },
    {
      headerName: 'Actions',
      field: 'ID',
      width: 150,
      renderCell: ({ value, row }) => (
        <>
          <IconButton
            onClick={() => {
              setViewUser(row)
            }}
          >
            <ViewIcon />
          </IconButton>
          {permission.write && (
            <IconButton
              onClick={() => {
                setEditUser(row)
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {currentUserId !== row.id && permission.delete && (
            <IconButton
              onClick={() => {
                setDeleteUser(row)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ]

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={usersStyles.titleWrapper}>
          <Box />

          <Box>
            <Button
              startIcon={<AddIcon />}
              onClick={() => {
                setAddUser(true)
              }}
            >
              {CONTENT_LABELS.addButton}
            </Button>
          </Box>
        </Box>
        <Box sx={usersStyles.tableWrapper}>
          <DataGrid
            apiRef={gridApiRef}
            rows={list}
            columns={columns}
            rowCount={count}
            onPaginationModelChange={(data) => {
              getUserList({ currentPage: data.page + 1 })
              setCurrentPage(data.page + 1)
            }}
            loading={isLoading}
          />
        </Box>
      </Card>

      {viewUser && (
        <ViewCrmUser
          open
          data={viewUser}
          onClose={() => {
            setViewUser(undefined)
          }}
        />
      )}

      {addUser && (
        <AddCrmUser
          open
          onAddSuccess={onAddSuccess}
          onClose={() => {
            setAddUser(false)
          }}
        />
      )}

      {editUser && (
        <EditCrmUser
          open
          data={editUser}
          onEditSuccess={onEditSuccess}
          onClose={() => {
            setEditUser(undefined)
          }}
        />
      )}

      {deleteUser && (
        <Dialog
          header={CONTENT_LABELS.deleteUser.heading}
          description={CONTENT_LABELS.deleteUser.description}
          open
          onClose={() => {
            setDeleteUser(undefined)
          }}
          primaryButton={CONTENT_LABELS.deleteUser.button}
          primaryButtonProps={{ color: 'error', onClick: onDelete, loading: isDeleting }}
        />
      )}
    </Box>
  )
}

const CONTENT_LABELS = {
  pageTitle: 'Users Listing',
  filterButton: 'Filters',
  addButton: 'Add User',
  filterModalHeader: 'Filter Data',
  downloadCSV: 'CSV',
  deleteUser: {
    heading: 'Delete User ?',
    description: 'After confirmation all user related information will be removed.',
    button: 'Yes, delete',
  },
}
