import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { promoCodeDetailsResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { PromoCodeResponse, PromoCodeListParams, PromoCodeListResponse, UpdatePromoCodeRequest } from './promoCode.types'


const addPromoCode = (data: UpdatePromoCodeRequest) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.postRequest<{}>(`promo-codes/add-promo-code`, { data })

const getPromoCode = (promoCodeId: number) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<PromoCodeResponse>(promoCodeDetailsResponse) : apiHandler.getRequest<PromoCodeResponse>(`promo-codes/${promoCodeId}`)

const deletePromoCode = (promoCodeId: number) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.deleteRequest<{}>(`promo-codes/${promoCodeId}`)

const updatePromoCode = (promoCodeId: number, data: UpdatePromoCodeRequest) =>
  SHOULD_MOCK_APIS ? apiHandler.mockApi<{}>({}) : apiHandler.putRequest<{}>(`promo-codes/${promoCodeId}`, { data })

const promoCodeList = (data: PromoCodeListParams) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<PromoCodeListResponse>(promoCodeList)
    : apiHandler.postRequest<PromoCodeListResponse>(`promo-codes/list`, { data })

const promoCodeService = {
  addPromoCode,
  getPromoCode,
  promoCodeList,
  deletePromoCode,
  updatePromoCode,
}

export default promoCodeService
