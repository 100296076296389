export const HOME = ''
export const ANY_PATH = '*'
export const LOGIN_ROUTE = '/login'
export const VERIFY_CODE_ROUTE = '/verify-code'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const VIEW_SUBPATH = '/view'

/* ORDERS */
export const ORDERS_WRAPPER = '/orders/*'
export const ORDERS = '/orders'
export const CRM = '/crm'
export const BOOKINGS = '/bookings'

/* USERS */
export const USER_ANALYTICS_WRAPPER = '/user-analytics/*'
export const USER_ANALYTICS = '/user-analytics'

/* SUBSCRIBERS */
export const SUBSCRIBERS = '/subscribers'


/* PROMO_CODE */
export const PROMO_CODE = '/promo-code'


/* CUSTOMER */
export const CUSTOMER_WRAPPER = '/customer-analytics/*'
export const CUSTOMER = '/customer-analytics'

/* USERS */
export const USERS_WRAPPER = '/user/*'
export const USERS = '/user'

/* CRM USERS */
export const CRM_USERS_WRAPPER = '/crm-user/*'
export const CRM_USERS = '/crm-user'

export const PATHS = {
  HOME,
  ANY_PATH,
  LOGIN_ROUTE,
  VERIFY_CODE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  VIEW_SUBPATH,
  ORDERS_WRAPPER,
  ORDERS,
  CRM,
  BOOKINGS,
  USER_ANALYTICS,
  USER_ANALYTICS_WRAPPER,
  SUBSCRIBERS,
  PROMO_CODE,
  CUSTOMER_WRAPPER,
  CUSTOMER,
  USERS_WRAPPER,
  USERS,
  CRM_USERS_WRAPPER,
  CRM_USERS,
}
