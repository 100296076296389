import styled from '@emotion/styled'
import { Dialog } from '@mui/material'

export const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: 24,
    "@media (max-width: 600px)":  {
      padding: 8,
    },
  },
  '& .MuiDialogTitle-root': {
    fontSize: '18px',
    color: 'rgba(47, 43, 61, 0.9)',
  },
  '& .MuiDialogActions-root': {
    '& button': {
      minWidth: 100,
    },
  },
}))
