import { RightSidebarProps } from 'components/Navigation/Sidebar'
import { User } from 'redux/Auth/auth.state'
import { validationSchema } from './Components'
import { useFormik } from 'formik'
import { useEditCrmData } from './EditCrmUser.controller'
import { useCallback, useState } from 'react'
import { crmUsersService } from 'services'
import useToast from 'components/UI/Toast'
import AddUserDrawer from './AddUserDrawer'

interface EditCrmUserProps extends Omit<RightSidebarProps, 'headerText'> {
  data: User
  onEditSuccess: (user: User) => void
}

export default function EditCrmUser({ data, onEditSuccess, ...props }: EditCrmUserProps) {
  const { domains, permissions, onPermissionChange, onDomainChange } = useEditCrmData(data)
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: { name: data.name, email: data.email },
    validationSchema,
    validateOnChange: true,
    onSubmit: () => {},
    validateOnMount: true,
  })

  const onSave = useCallback(async () => {
    if (!formik.isValid) {
      formik.submitForm()
      return
    }

    setLoading(true)
    const { values } = formik

    try {
      const userDomains = domains.filter((domain) => domain.checked).map((domain) => domain.id)
      const userPermissions = permissions.filter(
        (permission) =>
          permission.read ||
          permission.write ||
          permission.delete ||
          permission.canViewUserData ||
          permission.canViewPaymentData,
      )
      await crmUsersService.updateUser(data.id, {
        ...values,
        userDomains,
        permissions: userPermissions,
      })

      const { response } = await crmUsersService.getUser(data.id)
      onEditSuccess(response.data)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setLoading(false)
    }
  }, [data.id, domains, formik, onEditSuccess, permissions, toast])

  if (!data) return
  return (
    <AddUserDrawer
      headerText={data.name}
      loading={loading}
      onSave={onSave}
      domains={domains}
      onDomainChange={onDomainChange}
      permissions={permissions}
      onPermissionChange={onPermissionChange}
      formik={formik}
      {...props}
    />
  )
}
