import { Box, IconButton, SxProps } from '@mui/material'
import { Button } from 'components/Forms'
import { Card, DataGrid, PageTitle, GridColDef, Dialog } from 'components/UI'
import { useCallback, useMemo, useState } from 'react'
import { promoCodeService } from 'services'
import AddPromoCode from './__components__/AddPromoCode'
import { usePromoCodeListData } from './promoCode.controller'
import { useGridApiRef } from '@mui/x-data-grid'
import useToast from 'components/UI/Toast'
import { AddIcon, DeleteIcon, EditIcon } from 'components/UI/Icons'
import ViewPromoCode from './__components__/ViewPromoCode'
import EditPromoCode from './__components__/EditPromoCode'
import { readableDate } from 'helpers/Methods'
import { PromoCodeResponse } from 'services/promoCode.types'
import { MODULES } from 'helpers/Constants'
import { usePermission } from 'components/Core/Permissions'

export default function PromoCode() {
  const { list, setList, count, getPromoCodeList, isLoading } = usePromoCodeListData()
  const [currentPage, setCurrentPage] = useState(1)
  const [addPromoCode, setAddPromoCode] = useState(false)
  const [viewPromoCode, setViewPromoCode] = useState<PromoCodeResponse | undefined>()
  const [deletePromoCode, setDeletePromoCode] = useState<PromoCodeResponse | undefined>()
  const [editPromoCode, setEditPromoCode] = useState<PromoCodeResponse | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)
  const { getPermission } = usePermission()
  const toast = useToast()
  const permission = useMemo(() => getPermission(MODULES.PROMO_CODE), [getPermission])

  const gridApiRef = useGridApiRef()

  const onAddSuccess = useCallback(() => {
    getPromoCodeList({ currentPage })
  }, [currentPage, getPromoCodeList])

  const onDelete = useCallback(async () => {
    setIsDeleting(true)
    try {
      await promoCodeService.deletePromoCode(deletePromoCode.id)
      getPromoCodeList({ currentPage })
      setDeletePromoCode(undefined)
    } catch (error) {
      toast.error(error?.data?.message)
    } finally {
      setIsDeleting(false)
    }
  }, [currentPage, deletePromoCode?.id, getPromoCodeList, toast])

  const onEditSuccess = useCallback(
    (promoCode: PromoCodeResponse) => {
      const newList = [...list]

      newList.forEach((element, index) => {
        if (element.id === promoCode.id) newList[index] = { ...promoCode }
      })

      setList(newList)
      setEditPromoCode(undefined)
    },
    [list, setList],
  )

  const columns: GridColDef<PromoCodeResponse>[] = [
    { headerName: 'Promo Code', field: 'promo_code' },
    { headerName: 'Percent Amount', field: 'percent_amount' },
    { headerName: 'Flat Amount', field: 'flat_amount' },
    { headerName: 'Min Amount', field: 'min_amount' },
    { headerName: 'Max Amount', field: 'max_amount' },
    { headerName: 'Multi Use', field: 'multi_use', renderCell: ({ value }) => <>{value === 1 ? 'Yes' : 'No'}</> },
    { headerName: 'Single Use', field: 'single_use', renderCell: ({ value }) => <>{value === 1 ? 'Yes' : 'No'}</> },
    {
      headerName: 'Code Start Date',
      field: 'code_start_date',
      valueFormatter: (value) => {
        return readableDate(value)
      },
    },
    {
      headerName: 'Code End Date',
      field: 'code_end_date',
      valueFormatter: (value) => {
        return readableDate(value)
      },
    },
    {
      headerName: 'Actions',
      field: 'ID',
      width: 150,
      renderCell: ({ value, row }) => (
        <>
          {/* <IconButton
            onClick={() => {
              setViewPromoCode(row)
            }}
          >
            <ViewIcon />
          </IconButton> */}

          {permission.write && (
            <IconButton
              onClick={() => {
                setEditPromoCode(row)
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {permission.delete && (
            <IconButton
              onClick={() => {
                setDeletePromoCode(row)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ]

  return (
    <Box>
      <Box>
        <PageTitle>{CONTENT_LABELS.pageTitle}</PageTitle>
      </Box>

      <Card>
        <Box sx={promoCodeStyles.titleWrapper}>
          <Box />
          {permission.write && (
            <Box>
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  setAddPromoCode(true)
                }}
              >
                {CONTENT_LABELS.addButton}
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={promoCodeStyles.tableWrapper}>
          <DataGrid
            apiRef={gridApiRef}
            rows={list}
            columns={columns}
            rowCount={count}
            onPaginationModelChange={(data) => {
              getPromoCodeList({ currentPage: data.page + 1 })
              setCurrentPage(data.page + 1)
            }}
            loading={isLoading}
          />
        </Box>
      </Card>

      {viewPromoCode && (
        <ViewPromoCode
          open
          data={viewPromoCode}
          onClose={() => {
            setViewPromoCode(undefined)
          }}
        />
      )}

      {deletePromoCode && (
        <Dialog
          header={CONTENT_LABELS.deletePromoCode.heading}
          description={CONTENT_LABELS.deletePromoCode.description}
          open
          onClose={() => {
            setDeletePromoCode(undefined)
          }}
          primaryButton={CONTENT_LABELS.deletePromoCode.button}
          primaryButtonProps={{ color: 'error', onClick: onDelete, loading: isDeleting }}
        />
      )}

      {addPromoCode && (
        <AddPromoCode
          open
          onAddSuccess={onAddSuccess}
          onClose={() => {
            setAddPromoCode(false)
          }}
        />
      )}

      {editPromoCode && (
        <EditPromoCode
          open
          data={editPromoCode}
          onEditSuccess={onEditSuccess}
          onClose={() => {
            setEditPromoCode(undefined)
          }}
        />
      )}
    </Box>
  )
}

interface PromoCodeStylesType {
  titleWrapper: SxProps
  searchWrapper: SxProps
  toolsWrapper: SxProps
  tableWrapper: SxProps
  button: SxProps
}

const promoCodeStyles: PromoCodeStylesType = {
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchWrapper: { width: 300 },
  toolsWrapper: {},
  tableWrapper: {
    mt: 2,
  },
  button: {
    mx: 0.5,
  },
}

const CONTENT_LABELS = {
  pageTitle: 'Promo Code',
  filterButton: 'Filters',
  addButton: 'Add Promo Code',
  filterModalHeader: 'Filter Data',
  // downloadCSV: 'CSV',
  deletePromoCode: {
    heading: 'Delete Promo Code ?',
    description: 'After confirmation all promo code related information will be removed.',
    button: 'Yes, delete',
  },
}
