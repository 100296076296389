import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { bookingResponse, bookingDetailsResponse, exportOrdersCSVResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { BookingResponse, BookingDetailsResponse, BookingExportResponse } from './bookings.types'

const bookings = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<BookingResponse>(bookingResponse)
    : apiHandler.postRequest<BookingResponse>('bookings/list', { data })

const booking = (bookingId) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<BookingDetailsResponse>(bookingDetailsResponse)
    : apiHandler.getRequest<BookingDetailsResponse>(`bookings/${bookingId}`)

const updateBookingInfo = (bookingId: number, data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<{}>(bookingDetailsResponse)
    : apiHandler.putRequest(`bookings/${bookingId}`, { data })

const exportBookings = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<BookingExportResponse>(exportOrdersCSVResponse)
    : apiHandler.getRequest<BookingExportResponse>('bookings/export', data)

const bookingsService = {
  bookings,
  booking,
  updateBookingInfo,
  exportBookings,
}

export default bookingsService
