import { numberInputOnWheelPreventChange } from 'helpers/Methods'
import { object, string, number } from 'yup'

export const LABELS = {
  promoCodeInfo: 'Promo Code Details',
  addPromoCode: 'Add Promo Code',
}

export const formFieldsConfig = {
  promo_code: {
    placeholder: 'Promo code',
    label: 'Promo code',
    promo_code: 'promo_code',
    name: 'promo_code',
  },
  percent_amount: {
    placeholder: 'Percent amount',
    label: 'Percent amount',
    percent_amount: 'percent_amount',
    name: 'percent_amount',
    type: 'number',
    onWheel: numberInputOnWheelPreventChange,
  },
  flat_amount: {
    placeholder: 'Flat amount',
    label: 'Flat amount',
    flat_amount: 'flat_amount',
    name: 'flat_amount',
    type: 'number',
    onWheel: numberInputOnWheelPreventChange,
  },
  min_amount: {
    placeholder: 'Minimum amount',
    label: 'Minimum amount',
    min_amount: 'min_amount',
    name: 'min_amount',
    type: 'number',
    onWheel: numberInputOnWheelPreventChange,
  },
  max_amount: {
    placeholder: 'Maximum amount',
    label: 'Maximum amount',
    max_amount: 'max_amount',
    name: 'max_amount',
    type: 'number',
    onWheel: numberInputOnWheelPreventChange,
  },
  multi_use: {
    placeholder: 'Multi use',
    label: 'Multi use',
    multi_use: 'multi_use',
    name: 'multi_use',
  },
  single_use: {
    placeholder: 'Single use',
    label: 'Single use',
    single_use: 'single_use',
    name: 'single_use',
  },
  code_start_date: {
    placeholder: 'Code start date',
    label: 'Code start date',
    code_start_date: 'code_start_date',
    name: 'code_start_date',
  },
  code_end_date: {
    placeholder: 'Code end date',
    label: 'Code end date',
    code_end_date: 'code_end_date',
    name: 'code_end_date',
  },
}

export const validationSchema = object().shape({
  promo_code: string().required('Please enter promo code'),
  percent_amount: number()
    .nullable()
    .when('flat_amount', ([flat_amount, percent_amount], schema) => {
      return flat_amount || percent_amount
        ? schema.notRequired()
        : schema.required('Percentage or Flat amount is required')
    }),
  min_amount: number()
    .nullable()
    .when('flat_amount', ([flat_amount], schema) => {
      return flat_amount ? schema.required('Minimum amount is required') : schema.notRequired()
    }),
    max_amount: number()
    .nullable()
    .when('min_amount', ([min_amount], schema) =>
      min_amount
        ? schema.min(min_amount, 'Maximum amount must be greater than Minimum amount')
        : schema.notRequired()
    ),
  
})
