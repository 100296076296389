import { SHOULD_MOCK_APIS } from 'helpers/Constants'
import { newsLetterSubscribersResponse, exportNewsLetterResponse } from 'assets/mocks'
import apiHandler from 'helpers/ApiHandler'
import { NewsLetterSubscribersData, NewsLetterSubscribersListResponse } from './newsLetterSubscribers.types'

const newsLetterSubscribers = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<NewsLetterSubscribersListResponse>(newsLetterSubscribersResponse)
    : apiHandler.postRequest<NewsLetterSubscribersListResponse>('news-letters/list', {data})

const exportNewsLetterSubscribers = (data = {}) =>
  SHOULD_MOCK_APIS
    ? apiHandler.mockApi<NewsLetterSubscribersData>(exportNewsLetterResponse)
    : apiHandler.getRequest<NewsLetterSubscribersData>('exportNewsletter', data)

const newsLetterSubscribersService = {
  newsLetterSubscribers,
  exportNewsLetterSubscribers,
}

export default newsLetterSubscribersService
