import {
  Checkbox,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  OutlinedInput,
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

type MultiSelectPros = MuiSelectProps & {
  title: string
  value: string[] | number[]
  total: number
}

export default function MultiSelect(props: MultiSelectPros) {
  const { title, sx, MenuProps, children, total, ...restProps } = props

  const selectedLength = props.value.length
  const isAllSelected = selectedLength === total
  return (
    <MuiSelect
      input={<OutlinedInput />}
      multiple
      IconComponent={ExpandMoreIcon}
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        ...sx,
      }}
      MenuProps={{
        autoFocus: false,
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
          },
        },
        sx: {
          '& .MuiPaper-root': {
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.1)',
          },
          ...MenuProps?.sx,
        },
        ...MenuProps,
      }}
      {...restProps}
    >
      <MenuItem disabled value="">
        <em>{title}</em>
      </MenuItem>

      <MenuItem value="all">
        <Checkbox checked={isAllSelected} indeterminate={selectedLength > 0 && selectedLength < total} />
        <ListItemText primary={'Select All'} />
      </MenuItem>
      {children}
    </MuiSelect>
  )
}

interface MultiSelectItemProps extends MenuItemProps {
  value: string | number
  label: string
  checked: boolean
}

export function MultiSelectItem({ value, checked, label, ...rest }: MultiSelectItemProps) {
  return (
    <MenuItem key={value} value={value} {...rest}>
      <Checkbox checked={checked} />
      <ListItemText primary={label} />
    </MenuItem>
  )
}
