import { SxProps } from '@mui/material'
import { DRAWER_WIDTH, DRAWER_WIDTH_MINI, HEADER_HEIGHT } from 'helpers/Constants'
import { COLORS } from 'styles'

interface FormStylesTypes {
  wrapper: SxProps
  contentWrapper: SxProps
  contentWrapperClosed: SxProps
}

const dashboardStyles: FormStylesTypes = {
  wrapper: {
    display: 'flex',
    '.bs-logo': {
      height: 'auto',
      width: '150px',
    },
  },
  contentWrapper: {
    flexGrow: 1,
    p: {
      xs: 2,
      sm: 3
    },
    mt: `${HEADER_HEIGHT}px`,
    backgroundColor: COLORS.LIGHT_GREY,
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  contentWrapperClosed: {
    width: `calc(100% - ${DRAWER_WIDTH_MINI}px)`,
  },
}

export default dashboardStyles
