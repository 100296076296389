import { SxProps } from '@mui/material'

interface UsersListStylesType {
  domainAvatarWrapper: SxProps
  domainAvatar: SxProps
  titleWrapper: SxProps
}

export const usersListStyles: UsersListStylesType = {
  titleWrapper: { display: 'flex', justifyContent: 'space-between' },
  domainAvatarWrapper: { display: 'flex', gap: '5px', overflow: 'auto' },
  domainAvatar: { width: 24, height: 24, fontSize: '12px' },
}
