/* eslint-disable unicorn/filename-case */
import useToast from 'components/UI/Toast'
import { useCallback, useEffect, useState } from 'react'
import { promoCodeService } from 'services'
import { PromoCodeResponse, PromoCodeListParams } from 'services/promoCode.types'


export const usePromoCodeListData = () => {
    const [list, setList] = useState<PromoCodeResponse[]>([])
    const [count, setCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const toast = useToast()
  
    const getPromoCodeList = useCallback(
      async (params: PromoCodeListParams) => {
        setIsLoading(true)
        try {
          const { response } = await promoCodeService.promoCodeList(params)
          setList(response.data.promoCodes)
          setCount(response.data.count)
        } catch (error) {
          toast.error(error?.data?.message)
        } finally {
          setIsLoading(false)
        }
      },
      [toast],
    )
  
    useEffect(() => {
      getPromoCodeList({ currentPage: 1 })
    }, [getPromoCodeList])
  
    return {
      list,
      setList,
      getPromoCodeList,
      count,
      isLoading,
    }
  }