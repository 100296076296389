import { CHART_COLORS } from 'styles/colors'

/**
 * this method format number to currency format
 * @param string
 * @returns string
 */
export const currencyFormatter = (
  value: number | string,
  decimal: boolean = true,
  currencyCode: string = 'USD',
): string => {
  const formatterObject = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  if (!decimal) {
    formatterObject.minimumFractionDigits = 0
    formatterObject.maximumFractionDigits = 1
  }
  const formatter = new Intl.NumberFormat(undefined, formatterObject)

  return formatter.format(Number(value))
}

export const formatNumber = <T>(value: T): T => value

/**
 * parseFloat(value).toFixed(2)
 * this method generates chart colors based on array length
 * @param count total colors to be generated
 * @returns
 */
export function generateChartColors(count = 0) {
  const totalAvailableColors = CHART_COLORS.length
  if (count <= totalAvailableColors) return CHART_COLORS.slice(0, count)

  return [...CHART_COLORS, ...generateChartColors(count - totalAvailableColors)]
}

/**
 * this function return a random int with maximum number specified by param
 * @param min min value
 * @param max max value in range
 * @returns random int
 */

export function getRandomInt(min: number, max: number) {
  const minM = Math.ceil(min)
  const maxM = Math.floor(max)
  // eslint-disable-next-line sonarjs/pseudo-random
  return Math.floor(Math.random() * (maxM - minM + 1) + minM)
}

/**
 * this function returns keys with have provided value
 * @param object object to check
 * @param key key for a value to compare
 * @returns keys with same value
 */
export function getKeysWithSimilarValues(object, key: string) {
  return Object.keys(object).filter((objectKey) => object[objectKey] === object[key])
}

export function numberInputOnWheelPreventChange(event) {
  // Prevent the input value change
  event.target.blur()

  // Prevent the page/container scrolling
  event.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    event.target.focus()
  }, 0)
}

export function compareArrays(array1: any[], array2: any[]): boolean {
  // Check length first
  if (array1.length !== array2.length) return false

  // Sort and compare each element
  const sorted1 = [...array1].sort((a, b) => a - b)
  const sorted2 = [...array2].sort((a, b) => a - b)

  return sorted1.every((value, index) => value === sorted2[index])
}


export function hasValue(value) {
  return value !== undefined && value !== null
}

