import { Box, BoxProps, SxProps, Typography, TypographyProps } from '@mui/material'
import { COLORS } from 'styles'
import { cardShadow, fontWeight } from 'styles/theme'

export interface CardProps extends BoxProps {
  /**
   * makes card occupy 100% height of parent
   * @default false
   */
  fullHeight?: boolean

  /**
   * text that will be displayed as title
   */
  title?: string

  /**
   * config that will be supplied to CardTitle
   */
  titleProps?: CardTitleProps

  /**
   * text that will be displayed as title
   */
  subTitle?: string

  /**
   * config that will be supplied to CardTitle
   */
  subTitleProps?: CardTitleProps

  /**
   * (Optional) removes box shadow
   * @default false
   */
  noShadow?: boolean
}

export default function Card({
  fullHeight = false,
  sx,
  children,
  title,
  titleProps,
  subTitle,
  subTitleProps,
  noShadow = false,
  ...otherProps
}: CardProps) {
  return (
    <Box
      sx={
        [
          {
            borderRadius: '10px',
            backgroundColor: COLORS.WHITE,
            py: {
              xs: 1.5,
              sm: 2
            },
            px: {
              xs: 1.5,
              sm: 3
            },
            boxShadow: !noShadow && cardShadow,
          },
          fullHeight && { height: '100%' },
          sx,
        ] as SxProps
      }
      {...otherProps}
    >
      {title && <CardTitle label={title} {...titleProps} />}
      {subTitle && <CardSubTitle label={subTitle} {...subTitleProps} />}
      {children}
    </Box>
  )
}

export interface CardTitleProps extends TypographyProps {
  /**
   * text to be displayed
   */
  label: string

  /**
   * props to be applied to container Box elements
   */
  containerProps?: BoxProps
}

export function CardTitle({ label, containerProps, sx, ...otherProps }: CardTitleProps) {
  return (
    <Box {...containerProps}>
      <Typography sx={{ fontSize: 17, fontWeight: fontWeight.bold, ...sx }} {...otherProps}>
        {label}
      </Typography>
    </Box>
  )
}

export interface CardSubTitleProps extends TypographyProps {
  /**
   * text to be displayed
   */
  label: string

  /**
   * props to be applied to container Box elements
   */
  containerProps?: BoxProps
}

export function CardSubTitle({ label, containerProps, sx, ...otherProps }: CardTitleProps) {
  return (
    <Box {...containerProps}>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: fontWeight.medium,
          color: COLORS.LIGHT_TEXT,
          ...sx,
        }}
        {...otherProps}
      >
        {label}
      </Typography>
    </Box>
  )
}
