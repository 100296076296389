import { SxProps } from '@mui/material'
import { COLORS } from 'styles'
import { fontWeight } from 'styles/theme'

interface ComponentsStyles {
  wrapper: SxProps
  userEmail: SxProps
  emailIcon: SxProps
  sectionHeading: SxProps
  sectionCard: SxProps
  sectionCardHeading: SxProps
  sectionCardHeadingSeparator: SxProps
  domainItem: SxProps
  permissionLabel: SxProps
  userInfoContainer: SxProps
}

export const componentsStyles: ComponentsStyles = {
  wrapper: {
    backgroundColor: '#f8f7fa',
    width: { xs: "100%", sm: 475 },
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2.5,
    mt: 1,
  },
  userEmail: {
    display: 'flex',
    gap: 1.25,
    alignItems: 'center',
    '& p': {
      fontSize: '14px',
    },
  },
  emailIcon: {
    width: '20px',
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: fontWeight.medium,
    mt: 2,
    mb: 1,
  },
  sectionCard: {
    borderRadius: '5px',
    mb: 1,
    p: 2,
    fontSize: '12px',
  },
  sectionCardHeading: {
    fontSize: '14px',
    mb: 1,
    color: COLORS.PRIMARY_DARK,
  },
  sectionCardHeadingSeparator: {
    width: 40,
    height: '2px',
    mb: 0.5,
    mt: 0.25,
    background: COLORS.PRIMARY,
  },
  domainItem: {
    display: 'flex',
    gap: 1.25,
    my: 1.25,
    fontSize: '14px',
    alignItems: 'center',
    '&:first-of-type': {
      mt: 0,
    },
    '&:last-of-type': {
      mb: 0,
    },
  },
  permissionLabel: {
    fontSize: '14px',
  },
}
