// configs
export const SHOULD_MOCK_APIS = process.env.REACT_APP_MOCK_APIS === 'true'
export const API_URL = process.env.REACT_APP_API_URL

// Chart
export const CHART_TYPES = {
  COLUMN: 'column',
  PIE: 'pie',
  SCATTER: 'scatter',
  SPLINE: 'spline',
  TOP_X_LIST: 'topXList',
} as const

export const COMPATIBLE_CHARTS = {
  [CHART_TYPES.COLUMN]: 1,
  [CHART_TYPES.PIE]: 1,
  // [CHART_TYPES.SCATTER]: 1,
  [CHART_TYPES.SPLINE]: 1,

  [CHART_TYPES.TOP_X_LIST]: 2,
}

export const CHART_THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  DEEP_SAFFRON: 'deep_saffron',
  DARK: 'dark',
} as const

// styles
export const DRAWER_WIDTH = 260
export const DRAWER_WIDTH_MINI = 60
export const HEADER_HEIGHT = 80

export const BOOKING_STATUS_COLOR = {
  Created: 'primary',
  Pending: 'primary',
  Shipped: 'success',
  Processed: 'success',
  Error: 'error',
}

export const DOMAIN = {
  bookseats: { name: 'BookSeats', color: '#e96149' },
  golfpass: { name: 'GolfPass', color: '#000' },
  wwe: { name: 'WWE', color: '#e96149' },
  partner: { name: 'Partner', color: '#e96149' },
  vgk: { name: 'VGK', color: '#b4975a' },
  vegasgoldenknight: { name: 'VGK', color: '#b4975a' },
  packers: { name: 'Packers', color: '#06342D' },
  utes: { name: 'Utes', color: '#EA002A' },
  hurricanes: { name: 'Hurricanes', color: '#cc0000' },
  denverbroncos: { name: 'Broncos', color: '#002244' },
  newyorkjets: { name: 'Jets', color: '#115740' },
  wild: { name: 'Wild', color: '#0E4431' },
}

export const BOOKING_STATUS = {
  1: 'Created',
  2: 'Pending',
  3: 'Shipped',
  4: 'Processed',
  5: 'Error',
}
