import { Domain, Module, BookingStatus } from 'redux/Auth/auth.state'

export interface AppState {
  sideBarShow: boolean
  lookup: LookUpInfo
}

export const initialState: AppState = {
  sideBarShow: window.innerWidth > 600,
  lookup: {
    domains: [],
    modules: [],
    bookingStatus: []
  },
}

export interface LookUpInfo {
  domains: Domain[]
  modules: Module[]
  bookingStatus: BookingStatus[]
}
